<template>
    <a-modal width="100%" wrap-class-name="full-modal" :visible="true" title="Contact Permission" :ok="handleOk"
        :onCancel="handleCancel" :maskClosable="false">
        <a-row :gutter="25">
            <a-col :span="12"> <a-input v-model:value="searchValue" @change="onHandleSearch"
                    placeholder="Search for permission">
                    <template #suffix>
                        <sdFeatherIcons type="search" size="16" />
                    </template>
                </a-input></a-col>
            <a-col class="action-buttons" :span="4"><a-button @click="addAll">Add all permission to user</a-button></a-col>
            <a-col class="action-buttons" :span="4"> <a-button danger @click="clearAll">Clear all</a-button></a-col>
            <a-col class="action-buttons" :span="4"> <a-button type="primary" @click="handleOk">Update</a-button></a-col>
        </a-row>
        <a-row :gutter="25">
            <a-col :span="8" class="permission-card" v-for="(permissionsArray, resourceName) in filteredPermissions"
                :key="resourceName">
                <a-card :title="resourceName" :bordered="true">
                    <span v-for="item in permissionsArray" :key="item.id">
                        <a-checkbox :checked="selectedUserHasPermission(item.id)" @change="addPermission(item.id)"> {{
                            item.description }}</a-checkbox><br />
                    </span>
                </a-card>
            </a-col>
        </a-row>
        <template #footer>
            <a-button key="back" @click="handleCancel">Cancel</a-button>
            <a-button key="submit" type="primary" @click="handleOk">Update</a-button>
        </template>
    </a-modal>
</template>
<script>
import { watch, onMounted, computed, defineComponent, ref } from 'vue';
import { useStore } from "vuex";

export default defineComponent({
    props: {
        userId: String,
    },
    setup(props, { emit }) {
        const searchValue = ref("");

        const alllPermisson = computed(() => state.contactApi.dataPermisson);
        const userPermission = computed(() => state.contactApi.userPermission);

        const { state, dispatch } = useStore();

        const selectedItems = ref([]);

        onMounted(() => {
            dispatch("contactGetAllPermisions");
            dispatch("contactUserPermisions", props.userId);
        });

        watch(userPermission, (newVal) => {
            if (newVal.length > 0) {
                selectedItems.value = newVal;
            }
        });


        const handleCancel = () => {
            dispatch("emptyUserPermission");
            emit("onCancel")
        }
        const handleOk = () => {
            const formState = {
                userId: props.userId,
                permissionId: selectedItems.value,
            }
            dispatch('contactUpdateUserPermisions', formState)
        }
        const clearAll = () => {
            selectedItems.value = [];
        }
        const addAll = () => {
            clearAll();
            for (const category in alllPermisson.value) {
                const items = alllPermisson.value[category];
                for (const item of items) {
                    selectedItems.value.push(item.id);

                }
            }
        }

        const filterOption = (input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        };
        const selectedUserHasPermission = (permissionId) => {
            return selectedItems.value.includes(permissionId)
        };

        const removePermission = (permissionId) => {
            let indexToRemove = selectedItems.value.indexOf(permissionId);

            if (indexToRemove !== -1) {
                selectedItems.value.splice(indexToRemove, 1);
            }
        };

        const filteredPermissions = computed(() => {
            const permissions = alllPermisson.value;
            if (searchValue.value === '') {
                // If searchValue is empty, return all permissions as-is
                return permissions;
            }
            // Filter permissions by description
            const filtered = {};
            for (const resource in permissions) {
                filtered[resource] = permissions[resource].filter(permission =>
                    permission.description.toLowerCase().includes(searchValue.value.toLowerCase())
                );
            }
           const filteredObject = Object.fromEntries(
            // eslint-disable-next-line no-unused-vars
                Object.entries(filtered).filter(([_, value]) => value.length > 0)
            );


            return filteredObject;
        });

        const onHandleSearch = () => {
        };


        const addPermission = (permissionId) => {
            selectedUserHasPermission(permissionId) ? removePermission(permissionId) : selectedItems.value.push(permissionId);
        };

        return {
            searchValue,
            onHandleSearch,
            selectedUserHasPermission,
            selectedItems,
            alllPermisson,
            filterOption,
            handleCancel,
            handleOk,
            clearAll,
            addAll,
            addPermission,
            filteredPermissions,
        };
    },
});
</script>
<style scoped lang="scss">
.action-buttons {
    text-align: end;
}

.permission-card {
    margin-top: 20px;
}
</style>